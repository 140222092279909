<template>
  <div id="system-message" class="system-message">
    <div class="system-message__header d-flex align-items-center">
      <button class="btn btn-back" @click="$router.go(-1)">
        <img :src="require('@/assets/image/left-arrow.svg')" alt="back" />
      </button>
      <AvatarBox
        imgUrl="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200"
        imgAlt="name"
        imgSize="47px"
      />
      <div class="_user d-flex flex-column">
        <p class="f-w6 _user__name">ニックネーム</p>
        <span class="f-w3 _user__status">Activating</span>
      </div>
    </div>
    <div class="detail-message system-message__body">
      <MessageBox />
      <MessageBox />
      <MessageBox boxClass="rtl" />
    </div>

    <div class="system-message__foot">
      <div class="typing d-flex align-items-center">
        <div
          class="typing__icon d-flex align-items-center justify-content-center"
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <span class="fs-11 f-w3">ニックネーム is typing</span>
      </div>
      <form
        class="bg-white d-flex align-items-start"
        @submit.prevent="handleSendMessage()"
      >
        <textarea
          name="message"
          v-model="msg"
          placeholder="メッセージを入力"
          class="f-w3 fs-14 text-left"
          rows="1"
        ></textarea>
        <button v-if="msg.length > 0" class="btn p-0">
          <img :src="require('@/assets/image/send.svg')" alt="Send message" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import AvatarBox from "@/views/Common/AvatarBox.vue";
import MessageBox from "./MessageBox.vue";

export default {
  name: "SystemMessage",
  components: {
    AvatarBox,
    MessageBox
  },
  data() {
    return {
      msg: ""
    };
  },
  metaInfo() {
    return {
      title: "システムメッセージ",
      titleTemplate: "%s | PreGo"
    };
  },
  mounted: function() {
    let textarea = document.querySelector("textarea");
    textarea.addEventListener("keydown", autosize);
    function autosize() {
      let el = this;
      el.style.cssText = "height:auto;";
      el.style.cssText = "height:" + el.scrollHeight + "px";
    }
  },
  methods: {
    handleSendMessage() {
      console.log(this.msg);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";

.system-message {
  height: 100vh;
  position: relative;
  background-color: #f8f8f8;
  &__header {
    padding: 8px 19px;
    border-bottom: 2px solid #c9c9c9;
    .btn-back {
      padding: 0;
      padding-right: 20px;
      img {
        height: 14px;
      }
    }

    .avatar-box {
      margin-right: 21px;
    }

    ._user {
      &__name {
        margin-bottom: 5px;
        color: #000;
        text-align: left;
        font-size: 14px;
      }

      &__status {
        position: relative;
        text-align: left;
        font-size: 12px;
        padding-left: 15px;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 1px;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #18b87c;
        }
      }
    }
  }

  &__body {
    padding: 19px;
    height: calc(100% - 64px - 101px);
    overflow: auto;
    position: relative;
  }

  &__foot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 19px 15px;
    background-color: #f8f8f8;
    z-index: 2;

    form {
      border-radius: 10px;
      padding: 0 10px;
      min-height: 37px;
      box-shadow: 0 -3px 15px #00000008;
    }

    textarea {
      border-radius: 10px;
      padding: 10px;
      resize: none;
      min-height: 37px;
      max-height: 150px;
      flex-grow: 1;
      &::placeholder {
        color: #9c9c9c;
      }
    }
    button {
      margin-top: 10px;
      img {
        display: block;
        width: 18px;
      }
    }
  }
}

.typing {
  margin-bottom: 10px;
  &__icon {
    @include borderRadius(29px, 29px, 50%);
    background-color: #ffffff;
    margin-right: 13px;
    span {
      @include borderRadius(4px, 4px, 50%);
      display: block;
      background-color: #9c9c9c;
      transition: ease;
      animation: wave linear infinite 1s;
      &:not(:last-child) {
        margin-right: 3px;
      }
      &:nth-child(1) {
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation-delay: 0.25s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  > span {
    color: #000000;
  }
}

@keyframes wave {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(2px);
  }
}
</style>
